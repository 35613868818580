import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface Column {
  name: string;
  key: string;
  minWidth?: number;
  align?: "center" | "left" | "right" | "inherit";
  format?: any;
}

export default function StickyHeadTable({
  refetch,
  data,
  dataLength,
  setLimit,
  columns,
  limit,
  setPageId,
  pageId,
  onClick,
  pageCount,
}: {
  data: Array<any>;
  columns: Array<Column>;
  dataLength?: any;
  limit?: any;
  setPageId?: Function;
  pageId?: any;
  refetch?: Function;
  setLimit?: Function;
  onClick?: Function;
  pageCount?: any;
}) {
  
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        boxShadow: "0px 1px 70px 1px #FDF7F0",
      }}
    >
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={`${index}-${column.name}-col`}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    background: "#FAF1E6",
                    color: "#494848",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => {
              return (
                <TableRow
                  sx={{ cursor: onClick ? "pointer" : "" }}
                  onClick={() => onClick && onClick(item)}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`${index}-table-row`}
                >
                  {columns.map((column) => {
                    const value: any = item[column.key];
                    return (
                      <TableCell
                        key={column.name}
                        align={column.align}
                        style={{ fontSize: 15, color: "#636362" }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

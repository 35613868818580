import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Wrapper from "Components/Wrapper";
import ArticleCard from "./card";
import { useDailog, useToast } from "Utils/hooks";
import Add from "./add";
import FabButton from "Components/Shared/fab";
import Toast from "Components/Shared/toast";
import { request } from "Utils";
import { useQuery } from "react-query";
import NoContentFound from "Components/Shared/noContentFound";
import Loading from "Components/Shared/loading";
import ErrorPage from "Components/Shared/error";

const listArticles = async () => {
  const { data } = await request.get({
    url: `/articles/`,
  });
  return data;
};

const Articles = () => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { toggleToast, toastMessage } = useToast();
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["articles"],
    queryFn: listArticles,
    retry: 2,
  });
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Box>
        {data?.length ? (
          <Grid container spacing={3}>
            {data.map(() => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ArticleCard />
              </Grid>
            ))}
          </Grid>
        ) : (
          <NoContentFound />
        )}
        <Add
          refetch={refetch}
          isOpen={isOpen}
          closeDialog={closeDialog}
          toggleToast={toggleToast}
        />

        <FabButton onClick={openDialog} />
        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Box>
    </Wrapper>
  );
};

export default Articles;

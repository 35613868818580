import { ChangeEvent, useState } from "react";

export const useForm = (submitAction: Function) => {
  const [values, setValues] = useState<any>({});
  const getData = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name || e.target.id]: e.target.value || e.target.checked,
    });
  };
  const getTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValues({
      ...values,
      [e.target.id]: e.target.value,
    });
  };
  const submit = (e: any) => {
    if (e) e.preventDefault();
    submitAction();
  };
  const reset = () => {
    setValues({});
  };
  const getEditor = (event: any, name: string) => {
    values[name] = event;
    setValues({ ...values });
  };

  return {
    values,
    getData,
    submit,
    reset,
    getEditor,
    getTextArea,
  };
};
export const useMenu: any = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuIsOpen = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  return {
    anchorEl,
    openMenu,
    menuIsOpen,
    closeMenu,
  };
};

export const useToast = () => {
  const [toastMessage, setToastMessage] = useState("");
  const toggleToast = (message: string) => setToastMessage(message);
  return {
    toastMessage,
    toggleToast,
  };
};

export const useDailog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);
  return { isOpen, closeDialog, openDialog };
};

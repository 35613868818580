import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { useState } from "react";
import Progress from "Components/Shared/loading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { UpdateFunc } from "./type";
import { formData, request } from "Utils";
import Image from "Components/Shared/image";
import Person2Outlined from "@mui/icons-material/Person2Outlined";

const UpdateAdmin: UpdateFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  data,
  refetch
}) => {
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const [previewImg, setPreviewImg] = useState(data?.header_image);
  const [profilePhoto, setProfilePhoto] = useState(data?.profile_photo);
  function preview(e: any) {
    const objectUrl = e.target.files && URL.createObjectURL(e.target.files[0]);
    values.header_image = e.target.files[0];
    objectUrl && setPreviewImg(objectUrl);
    return () => objectUrl && URL.revokeObjectURL(objectUrl);
  }

  function previewProfilePhoto(e: any) {
    const objectUrl = e.target.files && URL.createObjectURL(e.target.files[0]);
    values.profile_photo = e.target.files[0];
    objectUrl && setProfilePhoto(objectUrl);
    return () => objectUrl && URL.revokeObjectURL(objectUrl);
  }
  const mutation = useMutation(
    async () => {
      setIsloading(true);
      if (values.country) values.country = values.country.toLowerCase();
      return await request.patch({
        url: `/community/${data.id}/`,
        data: formData(values)
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      }
    }
  );

  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Update community details"
        maxWidth="sm"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={5}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <InputField
                type="text"
                label="Name"
                name="name"
                defaultValue={data.name}
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" } }}
              />
              <InputField
                type="text"
                label="Hashtags"
                name="hashtags"
                // defaultValue={data.hashtags}
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" }, mt: { xs: 4, sm: 0 } }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <InputField
                type="text"
                label="Country"
                name="country"
                defaultValue={data.country}
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" } }}
              />
              <FormControl
                sx={{ width: { xs: "100%", sm: "49%" }, mt: { xs: 4, sm: 0 } }}
              >
                <InputLabel id="demo-simple-select-label">Privacy</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.privacy || data.privacy}
                  label="Privacy"
                  onChange={(e) => getData(e as any)}
                >
                  <MenuItem value="public">Public</MenuItem>
                  <MenuItem value="private">Privacy</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <InputField
              type="text"
              label="Website"
              name="website"
              defaultValue={data.website}
              onChange={getData}
            />
            <TextareaAutosize
              defaultValue={data.bio}
              onChange={(e) => (values.bio = e.target.value)}
              placeholder="Typing bios"
              style={{ borderRadius: 5, padding: "20px" }}
              maxRows={4}
            />
            <InputField
              type="file"
              label="Image"
              id="header_image"
              onChange={preview}
              required
              sx={{ display: "none" }}
            />
            <Box
              onClick={() => {
                document.getElementById("header_image")?.click();
              }}
              sx={{
                border: "solid 1px #dbdbdb",
                textAlign: "Center",
                borderRadius: 2,
                py: 3,
                color: "#333333",
                cursor: "pointer"
              }}
            >
              {previewImg ? (
                <Image
                  src={previewImg}
                  alt={values.name}
                  height="200px"
                  width="100%"
                />
              ) : (
                <>
                  <Person2Outlined sx={{ fontSize: 100 }} />
                  <Typography textAlign="center">
                    Upload header image
                  </Typography>
                </>
              )}
            </Box>
            <InputField
              type="file"
              label="Image"
              id="profile_photo"
              onChange={previewProfilePhoto}
              required
              sx={{ display: "none" }}
            />
            <Box
              onClick={() => {
                document.getElementById("profile_photo")?.click();
              }}
              sx={{
                border: "solid 1px #dbdbdb",
                textAlign: "Center",
                borderRadius: 2,
                py: 3,
                color: "#333333",
                cursor: "pointer"
              }}
            >
              {profilePhoto ? (
                <Image
                  src={profilePhoto}
                  alt={values.name}
                  height="200px"
                  width="200px"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <>
                  <Person2Outlined sx={{ fontSize: 100 }} />
                  <Typography textAlign="center">
                    Upload profile photo
                  </Typography>
                </>
              )}
            </Box>
            <ActionButton type="submit" variant="contained">
              Update community
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default UpdateAdmin;

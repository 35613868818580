import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./privateRoutes";
import LoginPage from "Components/Auth/login";
import PageNotFound from "Components/Shared/pageNotFound";
import DashboardPage from "Components/Dashboard";
import SafetyReport from "Components/SafetyReport";
import AdminPage from "Components/Admin";
import RolesPage from "Components/Roles";
import ViewSafetyReport from "Components/SafetyReport/reportView";
import ProblemsReport from "Components/ProblemReport";
import Users from "Components/Users";
import ViewUserInfo from "Components/Users/viewUser";
import ViewPost from "Components/Post/viewPost";
import Groups from "Components/Groups";
import Articles from "Components/Articles";
import ViewArticle from "Components/Articles/viewArticle";
import SupportPage from "Components/Support";
import { session } from "Utils";
import DataAnalytics from "Components/DataAnalytics";

export default function RoutesPage() {
  const token = session.get("token");
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Navigate to="/cms" replace />} />
        <Route
          path="/cms"
          element={token ? <DashboardPage /> : <LoginPage />}
        />
        <Route path="/cms/data-analytics" element={<DataAnalytics />} />
        <Route path="/cms/safety-reports" element={<SafetyReport />} />
        <Route path="/cms/admin" element={<AdminPage />} />
        <Route path="/cms/roles" element={<RolesPage />} />
        <Route path="/cms/problem-reports" element={<ProblemsReport />} />
        <Route path="/cms/report-on/:id" element={<ViewSafetyReport />} />
        <Route path="/cms/users" element={<Users />} />
        <Route path="/cms/user/:id" element={<ViewUserInfo />} />
        <Route path="/cms/post/:id" element={<ViewPost />} />
        <Route path="/cms/groups" element={<Groups />} />
        <Route path="/cms/articles" element={<Articles />} />
        <Route path="/cms/article/:id" element={<ViewArticle />} />
        <Route path="/cms/support" element={<SupportPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

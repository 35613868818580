import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import SideDrawer from "./drawer";
import { useLocation } from "react-router-dom";
import { HeaderFunc } from "./type";
import { session } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";

const Header: HeaderFunc = () => {
  const loc = useLocation().pathname.split("/")[1];
  const user = session.get("user");
  console.log(user, "user");
  const { toggleToast, toastMessage } = useToast();

  return (
    <>
      <Box
        sx={{ position: "fixed", width: { xs: "100%", md: "88%", zIndex: 3 } }}
      >
        <AppBar
          position="static"
          sx={{
            background: "#FFFBF6",
            boxShadow: "none",
            borderTopLeftRadius: { md: 30 }
          }}
        >
          {/* <Toolbar sx={{ px: 0 }}> */}
          <Container maxWidth="xl">
            <Box
              py={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Typography
                variant="h4"
                fontWeight={500}
                component="div"
                sx={{ textTransform: "capitalize" }}
              >
                {loc}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                sx={{ cursor: "pointer" }}
              >
                <Hidden smDown>
                  <Avatar
                    src={user?.profile_photo}
                    alt="admin"
                    sx={{ mr: 1, height: 50, width: 50 }}
                  />
                </Hidden>
                <Box>
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    component="div"
                    pr={{ md: 2 }}
                    sx={{ textTransform: "capitalize", color: "#616161" }}
                  >
                    {user?.first_name} {user?.last_name}
                  </Typography>
                  <Hidden smDown>
                    <Typography
                      variant="body2"
                      component="div"
                      // pr={10}
                      style={{ fontSize: 12, color: "#616161" }}
                    >
                      {user?.role?.name}
                    </Typography>
                  </Hidden>
                </Box>
              </Box>

              <Hidden mdUp>
                <SideDrawer />
              </Hidden>
            </Box>
          </Container>
          {/* </Toolbar> */}
        </AppBar>
      </Box>
      {Boolean(toastMessage) && (
        <Toast
          open={Boolean(toastMessage)}
          message={toastMessage}
          onClose={() => toggleToast("")}
        />
      )}
    </>
  );
};

export default Header;

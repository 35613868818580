// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=PT+Serif:ital,wght@0,400;1,700&family=Roboto:wght@100&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 353px) {
  .loginLogImage {
    width: 250px;
    height: 85px;
  }
}
a {
  text-decoration: none;
}
.active {
  background: linear-gradient(90.45deg, #faa43f 0.32%, #ff6813 101.55%);
  color: white;
}
a:hover {
  background: linear-gradient(90.45deg, #faa43f 0.32%, #ff6813 101.55%);
  color: white !important;
  transition: background 500ms ease-in;
}

a:hover div p {
  color: white !important;
}
a:hover div svg {
  color: white !important;
}
.active div svg {
  color: white !important;
}
.active div p {
  color: white !important;
}
.truncated-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,oBAAoB;EACpB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE;IACE,YAAY;IACZ,YAAY;EACd;AACF;AACA;EACE,qBAAqB;AACvB;AACA;EACE,qEAAqE;EACrE,YAAY;AACd;AACA;EACE,qEAAqE;EACrE,uBAAuB;EACvB,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;AAClB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=PT+Serif:ital,wght@0,400;1,700&family=Roboto:wght@100&display=swap\");\nbody {\n  margin: 0;\n  font-family: \"Inter\";\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n@media screen and (max-width: 353px) {\n  .loginLogImage {\n    width: 250px;\n    height: 85px;\n  }\n}\na {\n  text-decoration: none;\n}\n.active {\n  background: linear-gradient(90.45deg, #faa43f 0.32%, #ff6813 101.55%);\n  color: white;\n}\na:hover {\n  background: linear-gradient(90.45deg, #faa43f 0.32%, #ff6813 101.55%);\n  color: white !important;\n  transition: background 500ms ease-in;\n}\n\na:hover div p {\n  color: white !important;\n}\na:hover div svg {\n  color: white !important;\n}\n.active div svg {\n  color: white !important;\n}\n.active div p {\n  color: white !important;\n}\n.truncated-text {\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useQuery } from "react-query";
import { request, session } from "Utils";
import Usermenu from "./menu";
import NoContentFound from "Components/Shared/noContentFound";
import FabButton from "Components/Shared/fab";
import AddConfig from "./add";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import { AdminPageFunc } from "./type";

const getAdmin = async ({ queryKey }: any) => {
  const [, pageId, limit] = queryKey;
  const { data } = await request.get({
    url: `/admins?pageId=${pageId}&limit=${limit}`,
  });
  return data;
};

const AdminPage: AdminPageFunc = () => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { toggleToast, toastMessage } = useToast();
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users"],
    queryFn: getAdmin,
    retry: 2,
    // staleTime: 86400000,
  });
  const isSupperAdmin =
    session.get("user").role === "SUPER_ADMIN" ? true : false;
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Full Name", key: "fullName" },
    { minWidth: 50, name: "Email", key: "email" },
    { minWidth: 50, name: "Status", key: "status" },
    { minWidth: 50, name: "Role", key: "role" },
    { minWidth: 50, name: "Action", key: "action" },
  ];
  const tempData = {
    users: [
      {
        id: "",
        fullName: "Sanni Deborah",
        email: "career.debbie@gmail.com",
        status: "ACTIVE",
        roleId: { _id: "124", name: "Admin" },
      },
      {
        id: "",
        fullName: "Sanni Deborah",
        email: "career.debbie@gmail.com",
        status: "ACTIVE",
        roleId: { _id: "124", name: "Admin" },
      },
      {
        id: "",
        fullName: "Sanni Deborah",
        email: "career.debbie@gmail.com",
        status: "BLOCKED",
        roleId: { _id: "124", name: "Admin" },
      },
      {
        id: "",
        fullName: "Sanni Deborah",
        email: "career.debbie@gmail.com",
        status: "ACTIVE",
        roleId: { _id: "124", name: "Admin" },
      },
      {
        id: "",
        fullName: "Sanni Deborah",
        email: "career.debbie@gmail.com",
        status: "ACTIVE",
        roleId: { _id: "124", name: "Admin" },
      },
      {
        id: "",
        fullName: "Sanni Deborah",
        email: "career.debbie@gmail.com",
        status: "ACTIVE",
        roleId: { _id: "124", name: "Admin" },
      },
      {
        id: "",
        fullName: "Sanni Deborah",
        email: "career.debbie@gmail.com",
        status: "BLOCKED",
        roleId: { _id: "124", name: "Admin" },
      },
      {
        id: "",
        fullName: "Sanni Deborah",
        email: "career.debbie@gmail.com",
        status: "ACTIVE",
        roleId: { _id: "124", name: "Admin" },
      },
    ],
  };
  const users = tempData?.users?.map((user, index: number) => ({
    index: ++index,
    role: user?.roleId?.name,
    ...user,
    action: (
      <Usermenu
        refetch={refetch}
        admin={user as any}
        toggleToast={toggleToast}
        isSupperAdmin={isSupperAdmin}
      />
    ),
  }));
  if(isLoading) return <Loading />
  if(error) return <ErrorPage error={error} />
  return (
    <Wrapper>
      <>
        <Container maxWidth="md">
          {users?.length ? (
            <Box pb={5} sx={{ height: users?.length <= 7 ? "77vh" : "" }}>
              <StickyHeadTable
                refetch={refetch}
                data={users}
                dataLength={data?.totalCount}
                columns={columns}
                //   setLimit={setLimit}
                //   limit={limit}
                //   setPageId={setPageId}
                //   pageId={pageId}
                // pageCount={data.pageCount}
              />
            </Box>
          ) : (
            <NoContentFound />
          )}
        </Container>
        <AddConfig
          isOpen={isOpen}
          closeDialog={closeDialog}
          toggleToast={toggleToast}
          refetch={refetch}
        />
        {isSupperAdmin && <FabButton onClick={openDialog} />}
        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </>
    </Wrapper>
  );
};

export default AdminPage;

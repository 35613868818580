import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputField from "Components/Shared/textField";

import ErrorPage from "Components/Shared/error";
import Wrapper from "Components/Wrapper";
import { useQuery } from "react-query";
import { calculateAge, request } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import StickyHeadTable from "Components/Shared/table";
import { useNavigate } from "react-router-dom";
import PageLoading from "Components/Shared/pageLoading";
import { useState } from "react";

const getUsers = async ({ queryKey }: any) => {
  const [, url] = queryKey;
  const { result } = await request.get({ url });
  return result.results ? result.results : result;
};

const Users = () => {
  const { toggleToast, toastMessage } = useToast();
  const navigate = useNavigate();

  const handleClick = (value: any) => {
    navigate(`/cms/user/${value?.id}`);
  };
  const [url, setUrl] = useState("/auth/profile/");
  const [search, setSearch] = useState("");
  const [pageSize, SetPageSize] = useState(50);
  const [page, setPage] = useState(1);

  const { data, error, isLoading } = useQuery({
    queryKey: ["Users", url],
    queryFn: getUsers,
    retry: 2
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Firstname", key: "first_name" },
    { minWidth: 50, name: "Lastname", key: "last_name" },
    { minWidth: 50, name: "Email", key: "email" },
    { minWidth: 50, name: "Username", key: "username" },
    { minWidth: 50, name: "Age", key: "age" },
    { minWidth: 50, name: "Gender", key: "gender" },
    { minWidth: 50, name: "Pronouns", key: "pronouns" },
    { minWidth: 50, name: "Country", key: "country" },
    { minWidth: 50, name: "DOB", key: "dob" }
  ];
  const safetyReport = data?.map((user: any, index: number) => ({
    index: ++index,
    ...user,
    age: calculateAge(user.dob)
  }));
  if (isLoading) return <PageLoading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }}
          mb={4}
        >
          <Typography component="span" fontWeight={700} mr={2}>
            Filter:
          </Typography>

          <InputField
            type="text"
            defaultValue={search}
            label="search text"
            onChange={(e) => setSearch(e.target.value)}
          />
          <InputField
            type="number"
            label="Page"
            defaultValue={page}
            onChange={(e) => setPage(Number(e.target.value))}
            sx={{ width: { xs: "8%" }, mx: 2 }}
          />
          <InputField
            type="number"
            defaultValue={pageSize}
            label="Page size"
            onChange={(e) => SetPageSize(Number(e.target.value))}
            sx={{ width: { xs: "8%" } }}
          />
          <Button
            onClick={() =>
              setUrl(
                search
                  ? `/search/users/?page=${page}&page_size=${pageSize}&search=${search}`
                  : "/auth/profile/"
              )
            }
            variant="contained"
            sx={{ ml: 2, py: 1.5, fontSize: 18, px: 4 }}
          >
            Search
          </Button>
        </Box>
        {data?.length ? (
          <Box pb={5}>
            <StickyHeadTable
              onClick={handleClick}
              data={safetyReport}
              columns={columns}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Container>
    </Wrapper>
  );
};
export default Users;

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { DashboardCardFunct } from "./types";

const DashboardCard: DashboardCardFunct = ({
  icon,
  count,
  title,
  link,
  bg
}) => {
  return (
    <Link to={`/cms/${link}`}>
      <Card sx={{ boxShadow: "0px 1px 70px 1px #f1f1f1", background: bg }}>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1
          }}
        >
          <Box>
            {icon}
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "capitalize"
              }}
              color="#FCFBF9"
            >
              {title}
            </Typography>
          </Box>
          <Typography
            variant="body1"
            style={{ fontWeight: 700, fontSize: 36, color: "#FCFBF9" }}
            color="#FCFBF9"
          >
            {count}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};
export default DashboardCard;

import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteForever from "@mui/icons-material/DeleteForeverOutlined";
import MenuItem from "@mui/material/MenuItem";
import { useDailog, useMenu } from "Utils/hooks";

import UpdateKYCtype from "./update";
import ConfirmDelete from "Components/Shared/confirmDelete";
import { RolemenuFunc } from "../Roles/type";
import MoreHoriz from "@mui/icons-material/MoreHoriz";

const Rolemenu: RolemenuFunc = (props) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const confirmDeleteModal = useDailog();
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreHoriz />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          <MenuItem disableRipple onClick={openDialog}>
            <EditOutlined /> Update
          </MenuItem>
          <MenuItem
            disableRipple
            onClick={confirmDeleteModal.openDialog}
            sx={{ color: "red" }}
          >
            <DeleteForever style={{ color: "red" }} /> Delete
          </MenuItem>
        </div>
      </Menus>
      <UpdateKYCtype
        refetch={props.refetch}
        isOpen={isOpen}
        closeDialog={closeDialog}
        toggleToast={props.toggleToast}
        role={{ name: props.name, id: props.id }}
      />
      <ConfirmDelete
        refetch={props.refetch}
        isOpen={confirmDeleteModal.isOpen}
        closeDialog={confirmDeleteModal.closeDialog}
        toggleToast={props.toggleToast}
        url={`/role/${props.id}`}
      />
    </>
  );
};

export default Rolemenu;

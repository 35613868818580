import axios from "axios";
import { PostRequestInt, RequestResponseInt } from "./interface";
import moment from "moment";

export const DevLog = (data: any) => console.log("==============", data);

const BASE_URL =
  process.env.REACT_APP_API_URL;

export const request = {
  post: async ({
    url,
    data,
    method = "POST"
  }: PostRequestInt): Promise<RequestResponseInt> => {
    try {
      const headers = { authorization: `Bearer ${session.get("token")}` };
      const response = await axios({
        method,
        headers,
        url: BASE_URL + url,
        data
      });
      return response.data;
    } catch (err: any) {
      let error = err.response.data.detail;
      if (err.response.data.result) {
        const key = Object.keys(err.response.data.result);
        error = `${key[0]}, ${err.response.data.result[key[0]][0]}`;
      }
      throw {
        message: error,
        statusCode: err.status
      };
    }
  },
  get: async ({ url, method = "GET" }: { url: string; method?: string }) => {
    try {
      const headers = { authorization: `Bearer ${session.get("token")}` };
      const response = await axios({
        url: BASE_URL + url,
        headers,
        method
        // data,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (url: string) => await request.get({ url, method: "DELETE" }),
  patch: async (params: PostRequestInt) =>
    await request.post({ ...params, method: "PATCH" }),
  put: async (params: PostRequestInt) =>
    await request.post({ ...params, method: "PUT" })
};

export const getQueryString = (queryKeys: Record<string, any>) => {
  let query = "?";
  Object.keys(queryKeys).forEach((key) => {
    if (queryKeys[key]) {
      if (query === "?") query = `${query}${key}=${queryKeys[key]}`;
      else query = `${query}&${key}=${queryKeys[key]}`;
    }
  });
  return query;
};

export const session = {
  get: (key: string) => {
    let value = localStorage.getItem(key);
    return parseJSON(value);
  },
  set: (key: string, value: any) => {
    value = JSON.stringify(value);
    localStorage.setItem(key, value);
  },
  remove: (key: string) => {
    localStorage.removeItem(key);
  }
};

export const parseJSON = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

export const clientLogout = () => {
  session.remove("user");
  session.remove("token");
  window.location.reload();
};

export async function getUserData(userId: string) {
  const { data } = await request.get({
    url: `/user/${userId}`
  });
  return data;
}

export function toLocaleDate(isoDateString: string) {
  const date = new Date(isoDateString);
  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short"
  };
  const readableDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return readableDate;
}

export const handleError = (err: any) => {
  if (err?.name === "AxiosError") {
    const { data } = err.response;
    err.message = data?.message || "Something went wrong with your request";
    err.statusCode = data?.httpStatusCode || 500;
  }
  const statusCode = err.statusCode || 500;
  const message = err.message || "Something went wrong";
  return { message, statusCode };
};

export const formData = (values: Record<string, any>) => {
  const data = new FormData();
  for (let key of Object.keys(values)) {
    data.append(key, values[key]);
  }
  return data;
};

export const calculateAge = (birthDate: string) => {
  const birthDateMoment = moment(birthDate, "YYYY-MM-DD");
  const today = moment();

  const age = today.diff(birthDateMoment, "years");
  return age;
};

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { clientLogout } from "Utils";
import Image from "./image";
import { useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ErrorPage({ error }: { error: any }) {
  const navigate = useNavigate();
  const err = error;
  //   const err = handleError(error);
  useEffect(() => {
    if (err.statusCode === 401 || err.message === "jwt expired") {
      clientLogout();
    }
  }, [err]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "85vh",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Image
          src="/images/error.svg"
          alt="unaeon error"
          height="100%"
          width={350}
        />
        <Typography color="primary" variant="body1" my={2}>
          {err?.message}
        </Typography>
        <Typography variant="h2" fontWeight={700}>
          {err?.statusCode}
        </Typography>
        <Button
          onClick={() => navigate("/cms")}
          sx={{
            marginTop: "10px",
          }}
        >
          GO HOME
        </Button>
      </Box>
    </Box>
  );
}

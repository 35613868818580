import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
      text: "Users, Groups, Communities, safety report Statistics",
    },
  },
};

export default function ChartStat() {
  const users = [
    { month: "Jan", count: 23 },
    { month: "Feb", count: 57 },
    { month: "Mar", count: 44 },
    { month: "Apr", count: 48 },
    { month: "May", count: 66 },
    { month: "Jun", count: 120 },
    { month: "Jul", count: 35 },
    { month: "Aug", count: 50 },
    { month: "Sep", count: 60 },
    { month: "Oct", count: 90 },
    { month: "Nov", count: 80 },
    { month: "Dec", count: 100 },
  ];
  const groups = [
    { month: "Jan", count: 10 },
    { month: "Feb", count: 23 },
    { month: "Mar", count: 45 },
    { month: "Apr", count: 24 },
    { month: "May", count: 33 },
    { month: "Jun", count: 25 },
    { month: "Jul", count: 12 },
    { month: "Aug", count: 54 },
    { month: "Sep", count: 76 },
    { month: "Oct", count: 26 },
    { month: "Nov", count: 34 },
    { month: "Dec", count: 66 },
  ];
  const communities = [
    { month: "Jan", count: 15 },
    { month: "Feb", count: 88 },
    { month: "Mar", count: 34 },
    { month: "Apr", count: 23 },
    { month: "May", count: 77 },
    { month: "Jun", count: 50 },
    { month: "Jul", count: 45 },
    { month: "Aug", count: 19 },
    { month: "Sep", count: 100 },
    { month: "Oct", count: 90 },
    { month: "Nov", count: 78 },
    { month: "Dec", count: 50 },
  ];
  const safety = [
    { month: "Jan", count: 67 },
    { month: "Feb", count: 12 },
    { month: "Mar", count: 34 },
    { month: "Apr", count: 67 },
    { month: "May", count: 23 },
    { month: "Jun", count: 14 },
    { month: "Jul", count: 14 },
    { month: "Aug", count: 43 },
    { month: "Sep", count: 21 },
    { month: "Oct", count: 1 },
    { month: "Nov", count: 4 },
    { month: "Dec", count: 2 },
  ];
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Users",
        data: labels.map(
          (x) => users?.filter((val) => val.month === x)[0]?.count,
        ),
        borderColor: "green",
        backgroundColor: "green",
      },
      {
        label: "Groups",
        data: labels.map(
          (x) => groups?.filter((val) => val.month === x)[0]?.count,
        ),
        borderColor: "orange",
        backgroundColor: "orange",
      },
      {
        label: "Communities",
        data: labels.map(
          (x) => communities?.filter((val) => val.month === x)[0]?.count,
        ),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Safety report",
        data: labels.map(
          (x) => safety?.filter((val) => val.month === x)[0]?.count,
        ),
        borderColor: "red",
        backgroundColor: "red",
      },
    ],
  };
  return (
    <Card elevation={0} sx={{ borderRadius: 4, pt: 1, px: 1 }}>
      <CardContent sx={{}}>
        <Line
          options={options}
          data={data}
          style={{ height: "100%", width: "100%", overflow: "hidden" }}
        />
      </CardContent>
    </Card>
  );
}

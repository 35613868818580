import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import ErrorPage from "Components/Shared/error";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request } from "Utils";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import StickyHeadTable from "Components/Shared/table";
import Action from "./action";
import PageLoading from "Components/Shared/pageLoading";

const getSafetyReport = async () => {
  const { data } = await request.get({ url: "/support/safety_pages/" });
  return data;
};

const ViewSafetyReport = () => {
  const { toggleToast, toastMessage } = useToast();
  const { isOpen, closeDialog, openDialog } = useDailog();
  const [values, setValues] = useState({});

  const handleClick = (value: any) => {
    setValues(value);
    openDialog();
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ["Users"],
    queryFn: getSafetyReport,
    retry: 2,
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Reported by", key: "username" },
    { minWidth: 50, name: "Subject", key: "subject" },
    { minWidth: 50, name: "Date", key: "date" },
    { minWidth: 50, name: "Status", key: "status" },
  ];
  const safetyReport = data?.map((safetyReport: any, index: number) => ({
    index: ++index,
    ...safetyReport,
  }));
  if (isLoading) return <PageLoading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Container maxWidth="md">
        {data?.length ? (
          <Box pb={5}>
            <StickyHeadTable
              onClick={handleClick}
              data={safetyReport}
              // dataLength={data?.totalsubject}
              columns={columns}
              // setLimit={setLimit}
              // limit={limit}
              // setPageId={setPageId}
              // pageId={pageId}
              // pagesubject={data.pagesubject}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
        <Action
          isOpen={isOpen}
          closeDialog={closeDialog}
          toggleToast={toggleToast}
          data={values}
        />
      </Container>
    </Wrapper>
  );
};
export default ViewSafetyReport;

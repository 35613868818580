import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Wrapper from "Components/Wrapper";
import GeneralTab from "Components/Shared/tab";
import CommunityGroup from "Components/CommunityGroup";
import SupportGroup from "Components/SupportGroup";

const SupportPage = () => {
  return (
    <Wrapper>
      <Container maxWidth="xl">
        <Box>
          <GeneralTab
            tabSx={{ width: 200 }}
            tab={["Support Groups", "Community Groups"]}
            tabPanel={[<SupportGroup />, <CommunityGroup />]}
          />
        </Box>
      </Container>
    </Wrapper>
  );
};
export default SupportPage;

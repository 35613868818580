import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/loading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { ActionFunc } from "../Users/type";
import Typography from "@mui/material/Typography";

const Action: ActionFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  refetch,
  data,
}) => {
  const { submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.put({
        url: "/groups/ban_unban_community",
        data,
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch && refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );

  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={`${data.ban ? "Ban" : "Unban"} user`}
        maxWidth="xs"
      >
        <form onSubmit={submit}>
          <Stack spacing={2} py={2}>
            <Typography>Are you sure you want to do this.</Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <ActionButton
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  textTransform: "capitalize",
                  fontSize: 14,
                  background: "#850C0C",
                }}
              >
                Yes continue
                {isLoading && (
                  <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
                )}
              </ActionButton>
            </Box>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default Action;

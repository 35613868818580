import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import EditOutlined from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteForever from "@mui/icons-material/DeleteForeverOutlined";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { useDailog, useMenu } from "Utils/hooks";

import UpdateKYCtype from "./update";
import ConfirmDelete from "Components/Shared/confirmDelete";
import { MenuFunc } from "./type";
import ViewMore from "./view";

const Menu: MenuFunc = (props) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const confirmDeleteModal = useDailog();
  const viewModal = useDailog();
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          <MenuItem disableRipple onClick={viewModal.openDialog}>
            <VisibilityOutlinedIcon /> View More
          </MenuItem>
          <MenuItem disableRipple onClick={openDialog}>
            <EditOutlined /> Update
          </MenuItem>
          {/* {props.isSupperAdmin && ( */}
          <MenuItem
            sx={{ color: "red" }}
            disableRipple
            onClick={confirmDeleteModal.openDialog}
          >
            <DeleteForever style={{ color: "red" }} /> Delete
          </MenuItem>
          {/* // )} */}
        </div>
      </Menus>
      <UpdateKYCtype
        refetch={props.refetch}
        isOpen={isOpen}
        closeDialog={closeDialog}
        toggleToast={props.toggleToast}
        data={props.data}
      />
      <ViewMore
        isOpen={viewModal.isOpen}
        closeDialog={viewModal.closeDialog}
        data={props.data}
      />
      <ConfirmDelete
        refetch={props.refetch}
        isOpen={confirmDeleteModal.isOpen}
        closeDialog={confirmDeleteModal.closeDialog}
        toggleToast={props.toggleToast}
        url={`/support/support_organisations/${props?.data?.id}/`}
      />
    </>
  );
};
export default Menu;

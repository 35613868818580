import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

export default function Messages() {
  const data = [1, 2, 3, 4, 5, 6];
  const message = [1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6];
  return (
    <>
      <Grid container mt={4}>
        <Grid item xs={1} lg={3}>
          {message.map(() => (
            <Box mb={2} sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                alt="post"
                src="/images/safety.jpeg"
                sx={{ width: 40, height: 40 }}
              />
              <Box ml={2}>
                <Typography component="p" variant="subtitle2" fontWeight={600}>
                  Sanni Deborah
                </Typography>
                <Typography component="p" variant="caption">
                  Yeah! I'd love to meet....
                </Typography>
              </Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={2} lg={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={2} lg={2}></Grid>
        <Grid item xs={2} lg={4}>
          {data.map((num) => (
            <Box
              mb={3}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: num % 2 ? "row-reverse" : "",
              }}
            >
              <Avatar
                alt="post"
                src="/images/safety.jpeg"
                sx={{ width: 30, height: 30 }}
              />
              <Typography
                component="p"
                variant="subtitle2"
                fontWeight={600}
                mx={1}
                sx={{
                  width: 210,
                  border: "solid 1px #dbdbdb",
                  p: 2,
                  borderRadius: 3,
                }}
              >
                {" "}
                Yeah! I'd love to meet Sanni Deborah Yeah! I'd love to meet
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
      <Box mt={3} sx={{ maxWidth: 500, width: "100%" }}></Box>
    </>
  );
}

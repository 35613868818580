import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import ErrorPage from "Components/Shared/error";
import { useState } from "react";
import { useQuery } from "react-query";
import { request, toLocaleDate } from "Utils";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import StickyHeadTable from "Components/Shared/table";
import PageLoading from "Components/Shared/pageLoading";
import Action from "./action";

const listSafetyReport = async () => {
  const { result } = await request.get({
    url: "/cms/safetyreports/?resolved=true",
  });
  return result;
};

const ResolvedSafetyReport = () => {
  const { toggleToast, toastMessage } = useToast();
  const { isOpen, closeDialog, openDialog } = useDailog();
  const [values, setValues] = useState({});

  const handleClick = (value: any) => {
    setValues(value);
    openDialog();
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ["safetyreport"],
    queryFn: listSafetyReport,
    retry: 2,
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Reporter", key: "user" },
    { minWidth: 50, name: "Reported User", key: "reported_user" },
    { minWidth: 50, name: "Subject", key: "subject" },
    { minWidth: 50, name: "Report", key: "report_text" },
    { minWidth: 50, name: "Resolution", key: "resolution_text" },
    { minWidth: 50, name: "Reported Date", key: "reported_date_time" },
    { minWidth: 50, name: "Resolution Date", key: "resolved_date_time" },
  ];
  const safetyReports = data?.map((safetyReport: any, index: number) => ({
    index: ++index,
    ...safetyReport,
    user: safetyReport?.user?.username,
    reported_user: safetyReport?.reported_user?.username,
    reported_date_time: toLocaleDate(safetyReport?.reported_date_time),
    resolved_date_time: toLocaleDate(safetyReport?.resolved_date_time),
  }));
  if (isLoading) return <PageLoading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Container maxWidth="xl">
      {data?.length ? (
        <Box pb={5}>
          <StickyHeadTable
            onClick={handleClick}
            data={safetyReports}
            // dataLength={data?.totalCount}
            columns={columns}
            // setLimit={setLimit}
            // limit={limit}
            // setPageId={setPageId}
            // pageId={pageId}
            // pageCount={data.pageCount}
          />
        </Box>
      ) : (
        <NoContentFound />
      )}

      <Action
        isOpen={isOpen}
        closeDialog={closeDialog}
        toggleToast={toggleToast}
        data={values}
      />
      {Boolean(toastMessage) && (
        <Toast
          open={Boolean(toastMessage)}
          message={toastMessage}
          onClose={() => toggleToast("")}
        />
      )}
    </Container>
  );
};
export default ResolvedSafetyReport;

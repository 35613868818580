import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import Header from "./header";
import SideNav from "./sidenav";
import { WrapperFunc } from "./type";

const Wrapper: WrapperFunc = ({ children }) => {
  return (
    <div>
      <Grid container sx={{ background: "#FFFAF4" }}>
        <Hidden mdDown>
          <Grid item md={1.7} lg={1.5}>
            <SideNav />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={10.3} lg={10.5}>
          <Header />
          <Box component="main" pt={15.7} sx={{ background: "#FFFAF4" }}>
            <Container maxWidth="xl">{children}</Container>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
export default Wrapper;

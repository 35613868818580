import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// import ErrorPage from "Components/Shared/error";
// import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
// import { request } from "Utils";
import NoContentFound from "Components/Shared/noContentFound";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";

// const getAdmin = async ({ queryKey }: any) => {
//   const { data } = await request.get({
//     url: `/admins`,
//   });
//   return data;
// };

const ActivitiesPage = () => {
  // const { isOpen, closeDialog, openDialog } = useDailog();
  const { toggleToast, toastMessage } = useToast();
  // const [values, setValues] = useState({});
  // const { data, error, isLoading, refetch } = useQuery({
  //   queryKey: ["Users", pageId, limit],
  //   queryFn: getAdmin,
  //   retry: 2,
  //   // staleTime: 86400000,
  // });
  // const handleClick = (value:any) => {
  //   setValues(value)
  //   openDialog()
  // }
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "activity", key: "fullName" },
    { minWidth: 50, name: "Date", key: "email" },
  ];
  const tempData = {
    users: [
      {
        id: "",
        fullName: "Liked a post by @testuser",
        email: "31/12/23 - 15:02",
      },
      {
        id: "",
        fullName: "Commented on a post by @testuser",
        email: "31/12/23 - 15:02",
      },
      { id: "", fullName: "Followed @testuser", email: "31/12/23 - 15:02" },
      {
        id: "",
        fullName: "Became friends with @testuser",
        email: "31/12/23 - 15:02",
      },
      {
        id: "",
        fullName: "Joined the Support Group @testsupportgroup",
        email: "31/12/23 - 15:02",
      },
      {
        id: "",
        fullName: "Joined the Community @testcommunity",
        email: "31/12/23 - 15:02",
      },
      {
        id: "",
        fullName: "Liked a post by @testuser",
        email: "31/12/23 - 15:02",
      },
      {
        id: "",
        fullName: "Commented on a post by @testuser",
        email: "31/12/23 - 15:02",
      },
    ],
  };
  const users = tempData?.users?.map((user, index: number) => ({
    index: ++index,
    ...user,
  }));
  // if(isLoading) return <Loading />
  // if(error) return <ErrorPage error={error} />
  return (
    <>
      <Container maxWidth="md">
        {users?.length ? (
          <Box pb={5} sx={{ height: users?.length <= 7 ? "77vh" : "" }}>
            <StickyHeadTable
              // onClick={handleClick}
              data={users}
              columns={columns}
              //   setLimit={setLimit}
              //   limit={limit}
              //   setPageId={setPageId}
              //   pageId={pageId}
              // pageCount={data.pageCount}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}
      </Container>

      {Boolean(toastMessage) && (
        <Toast
          open={Boolean(toastMessage)}
          message={toastMessage}
          onClose={() => toggleToast("")}
        />
      )}
    </>
  );
};

export default ActivitiesPage;

import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loading = (props: CircularProgressProps) => {
  return <Box sx={{height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
    <CircularProgress {...props} />
  </Box>
};
export default Loading;

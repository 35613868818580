import Groups2Outlined from "@mui/icons-material/Groups2Outlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Box from "@mui/material/Box";
import Wrapper from "Components/Wrapper";
import { DashboardFunc } from "./types";
import Grid from "@mui/material/Grid";
import DashboardCard from "./card";
import ChartStat from "./chart";

const DashboardPage: DashboardFunc = () => {
  const cardInfo = [
    {
      icon: <Groups2Outlined sx={{ color: "#FCFBF9", fontSize: 70 }} />,
      title: "Total Users",
      count: 15356,
      link: "/users-info",
      bg: "linear-gradient(90.45deg, #64BC46 0.32%, #558B2F 101.55%)"
    },
    {
      icon: <GroupOutlinedIcon sx={{ color: "#FCFBF9", fontSize: 70 }} />,
      title: "Support Group",
      count: 16890,
      link: "/users-info",
      bg: "linear-gradient(90.45deg, #FD8901 0.32%, #E55200 101.55%)"
    },
    {
      icon: <Groups2Outlined sx={{ color: "#FCFBF9", fontSize: 70 }} />,
      title: "Communities",
      count: 78675,
      link: "/users-info",
      bg: "linear-gradient(90.45deg, #AE4CDD 0.32%, #5C237E 101.55%)"
    },
    {
      icon: <Groups2Outlined sx={{ color: "#FCFBF9", fontSize: 70 }} />,
      title: "Safety Reports",
      count: 356,
      link: "/users-info",
      bg: "linear-gradient(90.45deg, #C34242 0.32%, #730606 101.55%)"
    }
  ];
  return (
    <Wrapper>
      <Box>
        <Grid container spacing={3}>
          {cardInfo.map(({ icon, title, count, link, bg }, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              xl={3}
              key={`dashboard card ${index}`}
            >
              <DashboardCard
                icon={icon}
                title={title}
                count={count}
                link={link}
                bg={bg}
              />
            </Grid>
          ))}
        </Grid>
        <ChartStat />
      </Box>
    </Wrapper>
  );
};
export default DashboardPage;

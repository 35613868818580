import Button, { ButtonProps } from "@mui/material/Button";

const BasicButtons = (props: ButtonProps) => {
  const { children, sx, ...rest } = props;
  return (
    <Button {...rest} sx={{ fontSize: 18, height: 60, py: 1.2, ...sx }}>
      {children}
    </Button>
  );
};
export default BasicButtons;

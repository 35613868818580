import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Wrapper from "Components/Wrapper";
import GeneralTab from "Components/Shared/tab";
import SupportOrganization from "Components/SupportOrganization";
import SupportLine from "Components/SupportLine";
import EmergencyNumber from "Components/Emergency";

const SupportPage = () => {
  return (
    <Wrapper>
      <Container maxWidth="xl">
        <Box>
          <GeneralTab
            tabSx={{ width: 200 }}
            tab={[
              "Support Organisations",
              "Support Lines",
              "Emergency Numbers",
            ]}
            tabPanel={[
              <SupportOrganization />,
              <SupportLine />,
              <EmergencyNumber />,
            ]}
          />
        </Box>
      </Container>
    </Wrapper>
  );
};
export default SupportPage;

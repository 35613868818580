import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import moment from "moment";

const User = ({ user }: any) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={7} lg={8}>
        <Box sx={{ maxWidth: 700 }} mt={2}>
          <Typography variant="h4" mb={1}>
            Bio
          </Typography>
          <Typography variant="body1">{user.bio}</Typography>

          <Typography variant="h4" mb={1} mt={3}>
            Experiences
          </Typography>
          {user.experiences.map(
            (experience: { type: string; info: string }, index: number) => (
              <Box>
                <Typography
                  variant="h5"
                  mb={0.5}
                  fontSize={15}
                  fontWeight={600}
                >
                  {experience.type}
                </Typography>
                <Typography variant="body1">{experience.info}</Typography>
              </Box>
            )
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={5} lg={3.5}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{}}>
            <Typography variant="subtitle2" mt={1}>
              Name
            </Typography>
            <Typography variant="h4">
              {user?.first_name} {user?.last_name}
            </Typography>
          </Box>
          <Box sx={{}}>
            <Typography variant="subtitle2" mt={1}>
              Pronouns
            </Typography>
            <Typography variant="h4">{user.pronouns}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{}}>
            <Typography variant="subtitle2" mt={3}>
              username
            </Typography>
            <Typography variant="h4">{user.username}</Typography>
          </Box>
          <Box sx={{}}>
            <Typography variant="subtitle2" mt={3}>
              D.O.B
            </Typography>
            <Typography variant="h4">{user.dob}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{}}>
            <Typography variant="subtitle2" mt={3}>
              Gender
            </Typography>
            <Typography variant="h4">{user.gender}</Typography>
          </Box>
          <Box sx={{}}>
            <Typography variant="subtitle2" mt={3}>
              Country
            </Typography>
            <Typography variant="h4">{user.country}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{}}>
            <Typography variant="subtitle2" mt={3}>
              User since:
            </Typography>
            <Typography variant="h4">
              {moment(user.created_at).format("YYYY-MM-DD")}
            </Typography>
          </Box>
          <Box sx={{}}>
            <Typography variant="subtitle2" mt={3}>
              Status
            </Typography>
            <Typography variant="h4">Active</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{}}>
            <Typography variant="subtitle2" mt={3}>
              Email
            </Typography>
            <Typography variant="h4">{user.email}</Typography>
          </Box>
          <Box sx={{}}>
            <Typography variant="subtitle2" mt={3}>
              Strikes
            </Typography>
            <Typography variant="h4">{user.strikes}</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle2" mt={3}>
            Website
          </Typography>
          <Typography variant="h4" mb={1}>
            {user.website}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default User;

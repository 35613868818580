import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import DialogComponent from "Components/Shared/dailog";
import Typography from "@mui/material/Typography";
import Image from "Components/Shared/image";

const ViewMore = ({ isOpen, closeDialog, data }: any) => {
  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={data.name}
        maxWidth="sm"
      >
        <Stack spacing={2} py={2}>
          <Box>
            <Image
              alt=""
              src={data?.profile_photo || "/images/safety.jpeg"}
              height={"100%"}
              width={"100%"}
              style={{ borderRadius: 6 }}
            />
            <Image
              alt=""
              src={data?.header_image || "/images/safety.jpeg"}
              height={100}
              width={100}
              style={{ borderRadius: "50%", marginTop: -50, marginLeft: 30 }}
            />
          </Box>
          <Typography variant="h4">{data.name}</Typography>
          <Typography>{data.bio}</Typography>
          <Box sx={{ display: "flex" }}>
            <Typography variant="subtitle2" fontWeight={600} marginRight={2}>
              {data.privacy}
            </Typography>
            <Typography variant="subtitle2" marginRight={2}>
              Ban status: {data.is_ban}
            </Typography>
            <Typography variant="subtitle2" marginRight={2}>
              Member count: {data.members_count}
            </Typography>
            <Typography variant="subtitle2" marginRight={2}>
              Official tick: {data.official_tick ? "Yes" : "No"}
            </Typography>
          </Box>
          <Typography variant="subtitle2">Website: {data.website}</Typography>
          <Box>
            <Typography variant="subtitle2" fontWeight={600}>
              Hashtag
            </Typography>
            {data?.hashtags?.map((value: any) => (
              <Typography variant="subtitle2">{value.hashtag}</Typography>
            ))}
          </Box>
        </Stack>
      </DialogComponent>
    </div>
  );
};

export default ViewMore;

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ErrorPage from "Components/Shared/error";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request } from "Utils";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import PageLoading from "Components/Shared/pageLoading";
import Action from "./action";
import GeneralTab from "Components/Shared/tab";
import User from "./about";
import Posts from "../Post/post";
import UserComments from "./comments";
import Messages from "./message";
import Article from "Components/Activities/activity";
import History from "Components/History";
import StrikeAction from "./strikeAction";

const getUser = async ({ queryKey }: any) => {
  const [, id] = queryKey;
  const { result } = await request.get({ url: `/auth/profile/${id}/` });
  return result;
};

const ViewUserInfo = () => {
  const { toggleToast, toastMessage } = useToast();
  const { isOpen, closeDialog, openDialog } = useDailog();
  const strikeModal = useDailog();
  const [ban, setban] = useState<boolean>();
  const location = useLocation();
  const id = location.pathname.split("/")[3];

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", id],
    queryFn: getUser,
    retry: 2
    // staleTime: 86400000,
  });

  const btnStyle = {
    color: "#fff",
    textTransform: "capitalize",
    mr: 3,
    px: 2,
    py: 0.5,
    fontSize: 16
  };
  if (isLoading) return <PageLoading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Container maxWidth="xl">
        {data ? (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap"
              }}
            >
              <Box mb={3} sx={{ display: "flex" }}>
                <Avatar
                  src={data.profile_photo || "/images/safety.jpeg"}
                  alt="user"
                  sx={{ mr: 1, height: 100, width: 100 }}
                />
                <Box>
                  <Typography variant="h3">
                    {data?.first_name} {data?.last_name}
                  </Typography>
                  <Typography variant="body2">{data.email}</Typography>
                  <Box sx={{ display: "flex" }} mt={1}>
                    <Box textAlign="center" mr={2}>
                      <Typography
                        variant="subtitle2"
                        color="#666564"
                        fontSize={14}
                      >
                        Community
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontSize={12}
                        fontWeight={700}
                      >
                        {data.created_community?.length}
                      </Typography>
                    </Box>
                    <Box textAlign="center" mr={2}>
                      <Typography
                        variant="subtitle2"
                        color="#666564"
                        fontSize={14}
                      >
                        Followers
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontSize={12}
                        fontWeight={700}
                      >
                        {data.followers_number ? data.followers_number : 0}
                      </Typography>
                    </Box>
                    <Box textAlign="center" mr={2}>
                      <Typography
                        variant="subtitle2"
                        color="#666564"
                        fontSize={14}
                      >
                        Following
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontSize={12}
                        fontWeight={700}
                      >
                        {data.followings_number
                          ? Number(data.followings_number)
                          : 0}
                      </Typography>
                    </Box>
                    <Box textAlign="center" mr={2}>
                      <Typography
                        variant="subtitle2"
                        color="#666564"
                        fontSize={14}
                      >
                        Friends
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontSize={12}
                        fontWeight={700}
                      >
                        {data.number_of_friends
                          ? Number(data.number_of_friends)
                          : 0}
                      </Typography>
                    </Box>
                    <Box textAlign="center">
                      <Typography
                        variant="subtitle2"
                        color="#666564"
                        fontSize={14}
                      >
                        Groups
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontSize={12}
                        fontWeight={700}
                      >
                        {data.created_support_groups?.length}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                {data.is_ban ? (
                  <Button
                    onClick={() => {
                      setban(false);
                      openDialog();
                    }}
                    sx={{
                      background: "#76BE1B",
                      ...btnStyle,
                      "&:hover": { background: "#76BE1B" }
                    }}
                    size="small"
                  >
                    Unban User
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setban(true);
                      openDialog();
                    }}
                    sx={{
                      background: "#FD3737",
                      ...btnStyle,
                      "&:hover": { background: "#FD3737" }
                    }}
                    size="small"
                  >
                    Ban User
                  </Button>
                )}

                <Button
                  onClick={() => {
                    setban(true);
                    strikeModal.openDialog();
                  }}
                  sx={{
                    background: "#876b07",
                    ...btnStyle,
                    "&:hover": { background: "#876b07" }
                  }}
                  size="small"
                >
                  Give Strike
                </Button>
              </Box>
            </Box>
            <GeneralTab
              tabSx={{ width: 100 }}
              tab={[
                "User Info",
                "Posts",
                "Comments",
                "Messages",
                "Activity",
                "History"
              ]}
              tabPanel={[
                <User user={data} />,
                <Posts userId={id} />,
                <UserComments userId={id} />,
                <Messages />,
                <Article />,
                <History />
              ]}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
        <Action
          isOpen={isOpen}
          closeDialog={closeDialog}
          toggleToast={toggleToast}
          data={{ ban, user_id: id }}
          refetch={refetch}
        />
        <StrikeAction
          isOpen={strikeModal.isOpen}
          closeDialog={strikeModal.closeDialog}
          toggleToast={toggleToast}
          data={{ user_id: id, strikes: data.strikes }}
          refetch={refetch}
        />
      </Container>
    </Wrapper>
  );
};
export default ViewUserInfo;

import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import Box from "@mui/material/Box";
import Progress from "Components/Shared/loading";
import DialogComponent from "Components/Shared/dailog";
import ActionButton from "Components/Shared/button";
import { useForm } from "Utils/hooks";
import { request } from "Utils";
import { useState } from "react";
import { useMutation } from "react-query";
import { AddFunc } from "./type";

const Add: AddFunc = ({ toggleToast, isOpen, closeDialog, refetch }) => {
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      if (values.country) values.country = values.country.toLowerCase();
      return await request.post({
        url: "/support/emergency_numbers/",
        data: values,
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );

  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Add new emergency line"
        maxWidth="sm"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={5}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <InputField
                type="text"
                label="Phone number"
                name="phone_number"
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" } }}
              />
              <InputField
                type="text"
                label="Country"
                name="country"
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" }, mt: { xs: 4, sm: 0 } }}
              />
            </Box>
            <ActionButton type="submit" variant="contained">
              Add Emergency Line
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default Add;

interface PropsInt {
  src: string;
  alt: string;
  height?: number | string;
  width?: number | string;
  style?: Record<string, any>;
}
export default function Image(props: PropsInt) {
  return <img {...props} alt={props.alt ? props.alt : "unaeon"} />;
}

import ConfirmDelete from "Components/Shared/confirmDelete";
import IconButton from "@mui/material/IconButton";
import DeleteForeverOutlined from "@mui/icons-material/DeleteForeverOutlined";
import { useDailog } from "Utils/hooks";

const DeleteComment = ({ refetch, toggleToast, id, commentId }: any) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  return (
    <>
      <IconButton size="small" onClick={openDialog}>
        <DeleteForeverOutlined sx={{ fontSize: 15, color: "red" }} />
      </IconButton>
      <ConfirmDelete
        refetch={refetch}
        isOpen={isOpen}
        closeDialog={closeDialog}
        toggleToast={toggleToast}
        url={`/comments/${commentId}/`}
        redirect="/cms/users"
      />
    </>
  );
};

export default DeleteComment;

import FavoriteIcon from "@mui/icons-material/Favorite";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CommentIcon from "@mui/icons-material/CommentOutlined";
import Wrapper from "Components/Wrapper";
import { request } from "Utils";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import PageLoading from "Components/Shared/pageLoading";
import ErrorPage from "Components/Shared/error";
import moment from "moment";
import ConfirmDelete from "Components/Shared/confirmDelete";
import { useDailog, useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import DeleteComment from "./deleteComment";

const getPost = async ({ queryKey }: any) => {
  const [, id] = queryKey;
  const { result: posts } = await request.get({ url: `/posts/${id}/` });
  const { result: comments } = await request.get({
    url: `/posts/${id}/post_comments/`
  });
  return { posts, comments };
};
export default function ViewPost() {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { toggleToast, toastMessage } = useToast();
  const location = useLocation();
  const postId = location.pathname.split("/")[3];

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", postId],
    queryFn: getPost,
    retry: 2
    // staleTime: 86400000,
  });

  const btnStyle = {
    color: "#fff",
    textTransform: "capitalize",
    mr: 3,
    px: 2,
    py: 0.5,
    fontSize: 16,
    "&:hover": { background: "red" }
  };

  if (isLoading) return <PageLoading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Container maxWidth="md">
        <Box mb={3}>
          <Avatar
            alt="post"
            src={data?.posts?.photo || "/images/safety.jpeg"}
            sx={{ width: 200, height: 180, float: "left", mr: 3 }}
            variant="rounded"
          />
          <Box textAlign="justify">
            <Typography
              variant="body1"
              lineHeight="23px"
              style={{ color: "#333333" }}
            >
              {data?.posts?.text}
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ color: "#616161" }}
              my={0.5}
            >
              By: {data?.posts?.owner_first_name} {data?.posts?.owner_last_name}
            </Typography>
            <Typography variant="subtitle2" style={{ color: "#616161" }}>
              {moment(data?.posts?.created_at).fromNow(true)} ago
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end" }}
              pr={2}
              pb={2}
            >
              {/* <Typography component="span" display="flex" alignItems="center">
                <IconButton size="small">
                  <FavoriteIcon fontSize="small" color="primary" />
                </IconButton>
                <Typography variant="caption">
                  {data?.posts?.like_reaction || 0}
                </Typography>
              </Typography> */}
              <Typography
                component="span"
                display="flex"
                alignItems="center"
                ml={1}
              >
                <IconButton size="small">
                  <FavoriteIcon fontSize="small" sx={{ color: "primary" }} />
                </IconButton>
                <Typography variant="caption">
                  {data?.posts?.number_of_likes}
                </Typography>
              </Typography>
              <Typography
                component="span"
                display="flex"
                alignItems="center"
                ml={1}
              >
                <IconButton size="small">
                  <CommentIcon fontSize="small" htmlColor="#616161" />
                </IconButton>
                <Typography variant="caption">
                  {data?.posts?.number_of_comments}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box pt={10}>
          <Divider sx={{ clear: "both", mb: 3 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4">Comments</Typography>
            <Button
              onClick={openDialog}
              sx={{ background: "#FD3737", ...btnStyle }}
              size="small"
            >
              Delete Post
            </Button>
          </Box>

          {data?.comments?.map(
            ({ comment_text, user, created_at, id }: any, index: number) => (
              <Box mb={1.5} key={index} sx={{ display: "flex" }}>
                <Avatar src={user.profile_photo} sx={{ mt: 1 }} />
                <Box ml={2}>
                  <Typography variant="caption" style={{ color: "#333333" }}>
                    {moment(created_at).fromNow(true)}
                  </Typography>
                  <Typography variant="subtitle2">{comment_text}</Typography>
                  <Typography variant="caption" style={{ color: "#333333" }}>
                    @{user?.first_name} {user?.last_name}
                  </Typography>{" "}
                  <Box sx={{ display: "flex" }} mt={0.5}>
                    <Typography
                      component="span"
                      display="flex"
                      alignItems="center"
                    >
                      <DeleteComment
                        closeDialog={closeDialog}
                        toggleToast={toggleToast}
                        id={postId}
                        commentId={id}
                      />
                    </Typography>
                    {/*
                    <Typography
                      component="span"
                      display="flex"
                      alignItems="center"
                    >
                      <IconButton size="small">
                        <FavoriteIcon sx={{ fontSize: 12 }} color="primary" />
                      </IconButton>
                      <Typography variant="caption">34</Typography>
                    </Typography> <Typography
                      component="span"
                      display="flex"
                      alignItems="center"
                      ml={1}
                    >
                      <IconButton size="small">
                        <CommentIcon
                          sx={{ fontSize: 12 }}
                          htmlColor="#333333"
                        />
                      </IconButton>
                      <Typography variant="caption">4</Typography>
                    </Typography>*/}
                  </Box>
                </Box>
              </Box>
            )
          )}
        </Box>
        <ConfirmDelete
          refetch={refetch}
          isOpen={isOpen}
          closeDialog={closeDialog}
          toggleToast={toggleToast}
          url={`/posts/${postId}`}
          redirect="/cms/users"
        />
        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Container>
    </Wrapper>
  );
}

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/loading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { ActionFunc } from "./type";
import Typography from "@mui/material/Typography";
// import Image from "Components/Shared/image";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const StrikeAction: ActionFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  refetch,
  data
}) => {
  const { getTextArea, values, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const [strike, setStrike] = useState(data.strikes === 0 ? true : false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      let res;

      strike
        ? (res = await request.post({
            url: "/auth/strike_user",
            data: { ...values, user_id: data.user_id }
          }))
        : (res = await request.put({
            url: "/auth/strike_user",
            data: { ...values, user_id: data.user_id }
          }));

      return res;
    },
    {
      onSuccess: ({ message }) => {
        closeDialog();
        setIsloading(false);
        toggleToast(message);
        refetch && refetch();
        setStrike(data.strikes <= 0 ? true : false);
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      }
    }
  );

  function submitForm() {
    mutation.mutate();
  }

  const form = (
    <form onSubmit={submit}>
      <Stack spacing={2} py={2}>
        <Box>
          <TextareaAutosize
            required
            onChange={(e) => getTextArea(e)}
            id="reason"
            style={{
              height: 80,
              width: "90%",
              borderColor: "#dbdbdb",
              borderRadius: 6,
              padding: "10px 20px"
            }}
            placeholder={
              strike ? "Reason for adding strike" : "Reason for removing strike"
            }
          />
        </Box>
        <ActionButton type="submit" variant="contained" size="small">
          Strike user
        </ActionButton>
      </Stack>
    </form>
  );
  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={`Strike user`}
        maxWidth="xs"
      >
        <Box>
          <Typography variant="body1" sx={{ fontSize: 16, lineHeight: 1.6 }}>
            Please confirm your intention. Do you want to remove strike or add
            strike?
          </Typography>
          <Box textAlign="right">
            <ActionButton
              onClick={() => {
                setStrike(false);
              }}
              sx={{ color: "green", fontSize: 16 }}
            >
              Yes, Remove Strike
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
            <ActionButton
              onClick={() => {
                setStrike(true);
              }}
              sx={{ color: "red", fontSize: 16 }}
            >
              Yes, Add Strike
            </ActionButton>
            {form}
          </Box>
        </Box>
      </DialogComponent>
    </div>
  );
};

export default StrikeAction;

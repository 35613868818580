import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import DialogComponent from "Components/Shared/dailog";
import Typography from "@mui/material/Typography";
import { SupportInt } from "./type";

const ViewMore = ({
  isOpen,
  closeDialog,
  data,
}: {
  isOpen: boolean;
  closeDialog: Function;
  data?: SupportInt;
}) => {
  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="View"
        maxWidth="xs"
      >
        <Stack spacing={2} py={3}>
          <Box>
            <Typography variant="h5" fontWeight={700}>
              Bio
            </Typography>
            <Typography variant="body2">{data?.bio}</Typography>
          </Box>
          <Box>
            <Typography variant="h5" fontWeight={700}>
              Website
            </Typography>
            <Typography variant="body2">{data?.website}</Typography>
          </Box>
          <Box>
            <Typography variant="h5" fontWeight={700} mb={1}>
              Working days
            </Typography>
            <Box
              mb={0.5}
              sx={{
                maxWidth: 300,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Day</Typography>
              <Typography>Opening</Typography>
              <Typography>Closing</Typography>
            </Box>

            {data?.working_days?.map(({ day, opening, closing }) => (
              <Box
                sx={{
                  maxWidth: 300,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography textTransform="capitalize">{day}</Typography>
                <Typography>{opening}</Typography>
                <Typography>{closing}</Typography>
              </Box>
            ))}
          </Box>
        </Stack>
      </DialogComponent>
    </div>
  );
};

export default ViewMore;

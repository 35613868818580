// import AdminPanelSettingsOutlined from "@mui/icons-material/AdminPanelSettingsOutlined";
// import WorkOutlineOutlined from "@mui/icons-material/WorkOutlineOutlined";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import Groups2Outlined from "@mui/icons-material/Groups2Outlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import TocOutlinedIcon from "@mui/icons-material/TocOutlined";
import Divider from "@mui/material/Divider";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { NavLink, useLocation } from "react-router-dom";
import Image from "Components/Shared/image";
import { clientLogout } from "Utils";
import { LinksFunc } from "./type";
import { Balance } from "@mui/icons-material";

const Links: LinksFunc = () => {
  const location = useLocation();
  const path = location.pathname;
  const iconSx = { mr: 1.5, fontSize: 20 };

  const links = [
    {
      link: "Dashboard",
      to: "/cms",
      icon: <HomeOutlinedIcon htmlColor="#737272" sx={iconSx} />
    },
    // {
    //   link: "Admin",
    //   to: "/cms/admin",
    //   icon: <AdminPanelSettingsOutlined htmlColor="#737272" sx={iconSx} />,
    // },
    // {
    //   link: "Roles",
    //   to: "/cms/roles",
    //   icon: <WorkOutlineOutlined htmlColor="#737272" sx={iconSx} />,
    // },
    {
      link: "Data analytics",
      to: "/cms/data-analytics",
      icon: <LeaderboardIcon htmlColor="#737272" sx={iconSx} />
    },
    {
      link: "Safety Reports",
      to: "/cms/safety-reports",
      icon: <HealthAndSafetyOutlinedIcon htmlColor="#737272" sx={iconSx} />
    },
    {
      link: "Problem Reports",
      to: "/cms/problem-reports",
      icon: <SettingsSuggestIcon htmlColor="#737272" sx={iconSx} />
    },
    {
      link: "Users",
      to: "/cms/users",
      icon: <Groups2Outlined htmlColor="#737272" sx={iconSx} />
    },
    {
      link: "Groups Info",
      to: "/cms/groups",
      icon: <GroupOutlinedIcon htmlColor="#737272" sx={iconSx} />
    },
    {
      link: "Content Page",
      to: "/cms/articles",
      icon: <TocOutlinedIcon htmlColor="#737272" sx={iconSx} />
    },
    {
      link: "Support Page",
      to: "/cms/support",
      icon: <Balance htmlColor="#737272" sx={iconSx} />
    }
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100vh"
      }}
    >
      <Box>
        <Box textAlign="left" p={2}>
          <Image
            src="/images/logo.png"
            alt="unaeon logo"
            height="100%"
            width="100%"
            style={{ maxWidth: 150 }}
          />
        </Box>
        <Divider sx={{ mt: 1, mb: 3 }} />
        <Stack spacing={0.9}>
          {links.map(({ icon, link, to }, index) => (
            <NavLink
              key={index}
              to={to}
              className={() => (path === to ? "active" : "")}
            >
              <ListItemButton>
                {icon}
                <Typography
                  variant="body1"
                  style={{ fontSize: 15 }}
                  fontWeight={400}
                >
                  {link}
                </Typography>
              </ListItemButton>
            </NavLink>
          ))}
        </Stack>
      </Box>
      <Box mb={3}>
        <ListItemButton sx={{ mt: 2 }} onClick={() => clientLogout()}>
          <LogoutOutlined sx={{ color: "red", mr: 1.5 }} />
          <Typography
            variant="body1"
            style={{ fontSize: 15, color: "red" }}
            fontWeight={400}
          >
            Logout
          </Typography>
        </ListItemButton>
      </Box>
    </Box>
  );
};
export default Links;
